import { css } from '@emotion/css';
import { RootState, useAppDispatch, useAppSelector } from 'cache/store';
import { colors } from 'constants/theme';
import { toggleFastLotteryHome } from 'features/configSlice';
import { forwardRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { scrollToTopPage } from 'utils/function';
const cdn = process.env.REACT_APP_CDN_URL;
const supportList = [
    {
        name: 'CSKH',
        id: 'CSKH',
        icon: require('assets/images/icons/home/cskh.png'),
    },
    {
        name: 'Telegram',
        id: 'TELEGRAM',
        icon: require('assets/images/icons/home/telegram-support.png'),
    },
    {
        name: 'Zalo',
        id: 'ZALO',
        icon: require('assets/images/icons/home/zalo.png'),
    },
    {
        name: 'Tải App',
        id: 'DOWNLOAD',
        icon: require('assets/images/icons/home/down-app.png'),
    },
    {
        name: 'SpeedTest',
        id: 'SPEEDTEST',
        icon: require('assets/images/icons/home/speed-test.png'),
    },
];
const sapcingTop = 240;

interface Props {}

function SupportArea(props: Props, ref: any) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { triggerFastLottery } = useAppSelector((state: RootState) => state.config);
    const [positionArea, setPositionArea] = useState(sapcingTop);

    useEffect(() => {
        if (ref.current) {
            ref.current.addEventListener('scroll', listenerEventScroll);

            return () => {
                if (ref.current) {
                    ref.current.removeEventListener('scroll', listenerEventScroll);
                }
            };
        }
    }, [triggerFastLottery]);

    const listenerEventScroll = (event: Event) => {
        var element: any = event.target;
        if (element.scrollTop <= sapcingTop) {
            const value = sapcingTop - element.scrollTop;
            setPositionArea(value);
        } else {
            setPositionArea(0);
        }
        if (element.scrollTop > 800) {
            if (!triggerFastLottery) {
                dispatch(toggleFastLotteryHome(true));
            }
        }
    };

    const selectPage = (id: string) => {
        if (id === 'DOWNLOAD') {
            navigate('/download');
            scrollToTopPage();
        } else if (id === 'SPEEDTEST') {
            window.open('https://alb88.online/');
        } else if (id === 'CSKH' && (window as any).LC_API) {
            (window as any).LC_API.open_chat_window();
        } else if (id === 'TELEGRAM') {
            const random = Math.random() < 0.5;
            window.open(random ? 'https://t.me/CSKHalobet88' : 'https://t.me/hotroalobet', '_blank');
        }
    };
    return (
        <div className={styles.support} style={{ top: `${positionArea}px` }}>
            {supportList.map((item) => (
                <div className={styles.supportItem} key={item.id} onClick={() => selectPage(item.id)} data-tooltip-id={item.id} data-tooltip-place="left" data-tooltip-class-name={styles.tooltipWrap}>
                    <img src={item.icon} alt="" />
                    <p>{item.name}</p>
                </div>
            ))}

            <Tooltip id="TELEGRAM">
                <div className={styles.baseTele}>
                    <div className="tele-item">
                        <img src={`${cdn}/image/tele-cs.jpg`} alt="" />
                        <p>@CSKHalobet88</p>
                    </div>
                    <div className="tele-item">
                        <img src={`${cdn}/image/tele-cs1.jpg`} alt="" />
                        <p>@hotroalobet</p>
                    </div>
                </div>
            </Tooltip>

            <Tooltip id="ZALO">
                <div className={styles.baseTele}>
                    <div className="tele-item">
                        <img src={`${cdn}/image/zalo-cs.png`} alt="" />
                    </div>
                </div>
            </Tooltip>
        </div>
    );
}

const styles = {
    support: css`
        position: fixed;
        right: 0;
        z-index: 10;
        border-radius: 12px 0 0 12px;
        background: #1a202c;
        border: 1px solid #1e62c5;
        padding: 2px 0;
    `,
    supportItem: css`
        cursor: pointer;
        text-align: center;
        padding: 8px;
        border-bottom: 1px solid #263045;
        p {
            font-size: 10px;
            color: ${colors.white};
        }
    `,
    tooltipWrap: css`
        background: ${colors.white} !important;
        padding: 8px !important;
        border-radius: 8px !important;
        opacity: 1 !important;
    `,
    baseTele: css`
        background: ${colors.white};
        display: flex;
        gap: 8px;
        .tele-item {
            text-align: center;
            img {
                width: 150px;
            }
            p {
                color: ${colors.darkPrimary};
                font-weight: 600;
            }
        }
    `,
};

export default forwardRef(SupportArea);
