import { css } from '@emotion/css';
import { colors } from 'constants/theme';

const verifyPhoneStyles = {
    wrap: css`
        color: ${colors.white};
    `,
    title: css`
        text-align: center;
        margin-bottom: 20px;
    `,
    optTitle: css`
        font-weight: 700;
        font-size: 14px;
        text-align: center;
        margin-bottom: 4px;
    `,

    phoneValue: css`
        text-align: center;
        color: ${colors.primary};
        font-weight: 600;
        margin-bottom: 20px;
    `,
};
export default verifyPhoneStyles;
