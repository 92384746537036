import { forwardRef, useEffect, useState } from 'react';
import { Auth, ConfirmationResult, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import toast from 'react-hot-toast';
import LoadingIcon from 'components/loading/LoadingIcon';
import OtpInput from 'components/otp-input/OtpInput';
import styles from './verify-phone.style';
interface Props {
    phone: string;
    onCancel: () => void;
    onSuccess: (idToken: string) => void;
    firebaseConfig: Auth;
    ref: any;
}
function VerifyPhone(props: Props, ref: any) {
    const { phone, onCancel, onSuccess, firebaseConfig } = props;
    const [loading, setLoading] = useState(false);
    const [confirmResult, setConfirmResult] = useState<ConfirmationResult>({} as ConfirmationResult);
    useEffect(() => {
        let phoneFormat = phone;
        if (phoneFormat[0] === '0') {
            phoneFormat.slice(1);
        }
        const signInWithPhone = async () => {
            try {
                generateRecatcha();

                const response = await signInWithPhoneNumber(firebaseConfig, `+84${phoneFormat}`, (window as any).recaptchaVerifier);
                setConfirmResult(response);
            } catch (error: any) {
                console.log(error.code);
                if (error.code == 'auth/too-many-requests') {
                    toast.error('Thao tác quá nhanh, Vui lòng thử lại sau vài phút');
                } else if (error.code === 'auth/invalid-phone-number') {
                    toast.error('Số điện thoại không hợp lệ');
                } else {
                    toast.error('Có lỗi xảy ra! Vui lòng liên hệ bộ phận hỗ trợ');
                }

                //reset recaptcha
                if (ref.current) {
                    ref.current.innerHTML = `<div id="recaptcha-container"></div>`;
                }
            }
        };
        signInWithPhone();
    }, []);

    const generateRecatcha = () => {
        (window as any).recaptchaVerifier = new RecaptchaVerifier(
            'recaptcha-container',
            {
                size: 'invisible',
            },
            firebaseConfig
        );
    };

    //get opt when login with number phone
    const handleVerifyOtp = async (otp: string, cb: () => void) => {
        if (otp === '' || otp === null) return;

        setLoading(true);
        try {
            const { user } = await confirmResult.confirm(otp);
            const idToken = await user.getIdToken();

            onSuccess(idToken);
        } catch (error: any) {
            console.log(error);
            if (error.code == 'auth/invalid-verification-code') {
                toast.error('Mã xác nhận không đúng!');
            }
            if (error.code == 'auth/code-expired') {
                toast.error('Mã xác nhận đã hết thời gian sử dụng!');
            }
            cb();
        } finally {
            setLoading(false);
        }
    };

    const handleCancelVerify = () => {
        //reset recaptcha
        if (ref.current) {
            ref.current.innerHTML = `<div id="recaptcha-container"></div>`;
        }
        onCancel();
    };

    return (
        <>
            <div className={`bg-popup-common ${styles.wrap}`}>
                <h6 className={styles.title}>Xác Minh Số Điện Thoại</h6>
                <h6 className={styles.optTitle}>Nhập mã xác nhận được gửi về thuê bao</h6>
                <p className={styles.phoneValue}>{phone}</p>
                <OtpInput onSubmit={handleVerifyOtp} onCancel={handleCancelVerify} />
            </div>

            {loading && <LoadingIcon />}
        </>
    );
}

export default forwardRef(VerifyPhone);
