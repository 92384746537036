import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseInitConfig = (config: any) => {
    if (Object.keys(config).length === 0) {
        config = firebaseConfig;
    } else {
        config = {
            apiKey: config.apikey,
            authDomain: config.authdomain,
            projectId: config.projectid,
            storageBucket: config.storagebucket,
            messagingSenderId: config.messagingsenderid,
            appId: config.appid,
            measurementId: config.measurementid,
        };
    }

    // Initialize Firebase
    const app = initializeApp(config);
    let auth = getAuth(app);
    return auth;
    // export default app;
};
const firebaseConfig = {
    apiKey: 'AIzaSyDeb1MmEZ9esJpGe4CLst_6KFCGCHO8RE0',
    authDomain: 'alb-verify.firebaseapp.com',
    projectId: 'alb-verify',
    storageBucket: 'alb-verify.appspot.com',
    messagingSenderId: '181521208342',
    appId: '1:181521208342:web:65a4c2783f784de5171137',
    measurementId: 'G-0L95WNR5XK',
};

export default firebaseInitConfig;
