import { css } from '@emotion/css';
import authApi from 'apis/authApi';
import game3rdApi from 'apis/game3rdApi';
import miniGameApi from 'apis/miniGameApi';
import { RootState, useAppDispatch, useAppSelector } from 'cache/store';
import LoadingIcon from 'components/loading/LoadingIcon';
import TagLabel from 'components/tag-label/TagLabel';
import globalStyle, { rotateAnimation, textWave } from 'constants/global.style';
import { colors } from 'constants/theme';
import { cacheUserInfo } from 'features/authSlice';
import { Game3rdMenuItem } from 'models/commonModel';
import { Game3rdLaunchGamePayload } from 'models/fundTransferModel';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { FaArrowDown } from 'react-icons/fa6';
import { IoMdClose } from 'react-icons/io';
import { NumericFormat } from 'react-number-format';
import { removeSeperateNumber } from 'utils/function';
const urlLiveXD = process.env.REACT_APP_MINIGAME_URL;
const isLocal = process.env.REACT_APP_ENV_LOCAL;

interface Balance3rdGame {
    balance: string;
    msg: string;
}
interface Props {
    gameData: Game3rdMenuItem;
    onClose: () => void;
}
function FastTransferGame(props: Props) {
    const { gameData, onClose } = props;
    const dispatch = useAppDispatch();
    const { userInfo, isLogged } = useAppSelector((state: RootState) => state.auth);
    const [amountGameTo, setAmountGameTo] = useState('');
    const [balance3rd, setBalance3rd] = useState<Balance3rdGame>({} as Balance3rdGame);
    const [loading, setLoading] = useState(false);

    const [loadingMainBalance, setLoadingMainBalance] = useState(false);
    const [loadingGameBalance, setLoadingGameBalance] = useState(false);
    useEffect(() => {
        checkMainBalance();
        if (gameData.providercode === 'XD' && gameData.gametype === 'LC') {
            checkMiniGameBalance();
        } else {
            checkBalance3rdGame();
        }
    }, [gameData]);

    const checkMainBalance = async (cb?: () => void) => {
        setLoadingMainBalance(true);
        try {
            const { data } = await authApi.getUserInfo();
            if (!data.errorCode) {
                dispatch(cacheUserInfo(data));
                if (cb) cb();
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoadingMainBalance(false);
        }
    };

    const checkBalance3rdGame = async (cb?: () => void) => {
        setLoadingGameBalance(true);
        setBalance3rd({} as Balance3rdGame);
        try {
            const { data }: any = await game3rdApi.checkBalance({ providercode: gameData.providercode });
            setBalance3rd(data);
            if (cb) cb();
        } catch (error) {
            console.log(error);
        } finally {
            setLoadingGameBalance(false);
        }
    };

    const checkMiniGameBalance = async (cb?: () => void) => {
        setLoadingGameBalance(true);

        try {
            const { data }: any = await miniGameApi.checkBalance({});
            setBalance3rd(data);
            if (cb) cb();
        } catch (error) {
            console.log(error);
        } finally {
            setLoadingGameBalance(false);
        }
    };

    const reloadBalance = (e: any, isMainBalance?: boolean) => {
        const elTarget = e.target as HTMLElement;
        const buttonEl = elTarget.closest('button');

        if (buttonEl) {
            buttonEl.classList.add('rotating');
        }
        if (isMainBalance) {
            checkMainBalance(() => {
                if (buttonEl) {
                    buttonEl.classList.remove('rotating');
                }
            });
        } else {
            if (gameData.providercode === 'XD' && gameData.gametype === 'LC') {
                checkMiniGameBalance(() => {
                    if (buttonEl) {
                        buttonEl.classList.remove('rotating');
                    }
                });
            } else {
                checkBalance3rdGame(() => {
                    if (buttonEl) {
                        buttonEl.classList.remove('rotating');
                    }
                });
            }
        }
    };

    const selectAllIn = () => {
        if (Number(userInfo.balance) < 500000000) {
            // lấy phần nguyên chia hết cho 1000
            const balane = Number(userInfo.balance) - (Number(userInfo.balance) % 1000);
            console.log(balane);
            setAmountGameTo(String(balane));
        } else {
            setAmountGameTo(String(500000000));
        }
    };

    const submitPlayGame = async () => {
        if (!isLogged) {
            toast.error('Vui lòng đăng nhập');
            return;
        }
        if (balance3rd.msg) {
            toast.error(balance3rd.msg);
            return;
        }

        if (gameData.providercode === 'XD' && gameData.gametype === 'LC') {
            setLoading(true);

            try {
                const { data } = await miniGameApi.launchGame({ gameType: 'LIVEXD' });
                if (!data.msg) {
                    window.open(`${urlLiveXD}/xd-live/?encrypted=${data.encrypted}`, '_blank');
                } else {
                    toast.error(data.msg);
                }
                onClose();
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        } else {
            setLoading(true);
            const payload: Game3rdLaunchGamePayload = {
                providercode: gameData.providercode,
                type: gameData.gametype,
                gameid: gameData.gameid,
                html5: 0, //1:mobile, 0: web
            };
            try {
                const { data } = await game3rdApi.launchGame(payload);
                if (!data.msg) {
                    window.open(data.url, '_blank');
                    onClose();
                } else {
                    toast.error(data.msg);
                }
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }
    };

    const submitTransfer = async () => {
        const amountTransfer = Number(removeSeperateNumber(amountGameTo));

        if (Number(userInfo.balance) < amountTransfer) {
            toast.error('Tài khoản chính không đủ');
            return;
        }
        if (amountTransfer % 1000 > 0) {
            toast.error('Số tiền giao dịch phải chia hết cho 1,000');
            return;
        }
        if (amountTransfer < 1e3) {
            toast.error('Số tiền giao dịch nhỏ nhất là 1,000');
            return;
        }
        if (amountTransfer > 5e8) {
            toast.error('Số tiền giao dịch nhiều nhất là 500,000,000');
            return;
        }

        if (gameData.providercode === 'XD' && gameData.gametype === 'LC') {
            setLoading(true);
            const payload = {
                type: 0, // deposit
                amount: amountTransfer,
            };

            try {
                const { data } = await miniGameApi.submitTransfer(payload);
                if (!data.msg) {
                    toast.success('Giao dịch thành công');
                    setAmountGameTo('');
                    checkMainBalance();

                    //reload balance mini game
                    checkMiniGameBalance();
                } else {
                    toast.error(data.msg);
                }
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        } else {
            setLoading(true);
            const payload = {
                from: 'MAIN',
                to: gameData.providercode,
                amount: amountTransfer,
            };

            try {
                const { data } = await game3rdApi.submitTransfer(payload);
                if (!data.msg) {
                    toast.success('Giao dịch thành công');
                    setAmountGameTo('');
                    checkMainBalance();

                    //reload balance game 3 chuyển quỹ
                    checkBalance3rdGame();
                } else {
                    toast.error(data.msg);
                }
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }
    };
    return (
        <>
            <div className={styles.wrapTransfer}>
                <div className={styles.heading}>
                    <h6 className={styles.title}>Chuyển Khoản Nhanh</h6>
                    <button type="button" className={styles.btnClose} onClick={onClose}>
                        <IoMdClose size={36} color={colors.primary} />
                    </button>
                </div>
                <div className={styles.wrapBalance}>
                    <div className={styles.groupBalance}>
                        <label>Tài Khoản Chính</label>
                        <p className={`${globalStyle.inputCommon} ${styles.amountValue}`}>
                            {loadingMainBalance ? (
                                <>
                                    <span className="dot-loading"></span>
                                    <span className="dot-loading"></span>
                                    <span className="dot-loading"></span>
                                </>
                            ) : userInfo.balance ? (
                                new Intl.NumberFormat().format(Number(userInfo.balance))
                            ) : (
                                0
                            )}
                        </p>
                        <button type="button" className={styles.btnRefresh} onClick={(e) => reloadBalance(e, true)}>
                            <img src={require('assets/images/icons/refresh.png')} alt="" />
                        </button>
                    </div>
                    <div className={styles.groupBalance}>
                        <label>{gameData?.name}</label>
                        <p className={`${globalStyle.inputCommon} ${styles.amountValue}`}>
                            {loadingGameBalance ? (
                                <>
                                    <span className="dot-loading"></span>
                                    <span className="dot-loading"></span>
                                    <span className="dot-loading"></span>
                                </>
                            ) : balance3rd.msg ? (
                                <span className={styles.msgError}>{balance3rd.msg}</span>
                            ) : (
                                new Intl.NumberFormat().format(Number(balance3rd.balance || 0))
                            )}
                        </p>
                        <button type="button" className={styles.btnRefresh} onClick={(e) => reloadBalance(e)}>
                            <img src={require('assets/images/icons/refresh.png')} alt="" />
                        </button>
                    </div>
                    <div className={styles.iconArrow}>
                        <FaArrowDown size={22} />
                    </div>
                </div>
                <div className={`${styles.groupBalance} ${styles.groupInput}`}>
                    <label htmlFor="">Số Tiền (VNĐ)</label>
                    <div className={styles.groupAmount}>
                        <div style={{ flex: 1 }}>
                            <NumericFormat
                                className={`${globalStyle.inputCommon} ${styles.inputAmount}`}
                                thousandSeparator=","
                                placeholder="Nhập số tiền..."
                                value={amountGameTo}
                                onChange={(e) => setAmountGameTo(e.target.value)}
                            />
                            <TagLabel label="Ít nhất 1,000 VNĐ - Nhiều nhất 500,000,000 VNĐ" />
                        </div>
                        <button type="button" className={styles.btnMax} onClick={selectAllIn}>
                            Tối đa
                        </button>
                    </div>
                </div>
                <div className={styles.action}>
                    <button type="button" className={globalStyle.btnSecondary} onClick={submitPlayGame}>
                        Vào Trò Chơi
                    </button>
                    <button type="button" className={globalStyle.btnPrimary} disabled={!amountGameTo} onClick={submitTransfer}>
                        Xác Nhận
                    </button>
                </div>
            </div>
            {loading && <LoadingIcon />}
        </>
    );
}

const styles = {
    wrapTransfer: css`
        background: ${colors.blackText};
        border-radius: 8px;
        border: 1px solid ${colors.darkPrimary};
        padding: 16px;
        color: ${colors.white};
    `,
    title: css`
        flex: 1;
        padding-left: 30px;
        text-align: center;
        font-size: 20px;
    `,
    btnClose: css`
        width: 40px;
        height: 40px;
        cursor: pointer;
    `,
    heading: css`
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    `,
    amountValue: css`
        border-color: transparent;
        flex: 0 0 calc(100% - 120px);
        max-width: calc(100% - 120px);
        height: 37px;
        .dot-loading {
            width: 6px;
            height: 6px;
            background: ${colors.yellow};
            border-radius: 8px;
            animation: ${textWave} 1s ease-in-out infinite;
            display: inline-block;
            margin-left: 2px;
            &:nth-child(2) {
                animation-delay: 0.1s;
            }
            &:nth-child(3) {
                animation-delay: 0.2s;
            }
        }
    `,
    wrapBalance: css`
        position: relative;
    `,
    iconArrow: css`
        position: absolute;
        left: 30px;
        top: 33px;
    `,
    groupBalance: css`
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        position: relative;
        label {
            display: block;
            margin-bottom: 4px;
            padding-left: 2px;
            flex: 0 0 120px;
        }
    `,
    btnRefresh: css`
        position: absolute;
        top: calc(50% - 13px);

        right: 2px;
        display: flex;
        padding: 4px;
        cursor: pointer;
        &.rotating {
            pointer-events: none;
            animation: ${rotateAnimation} 0.8s linear infinite;
        }
        img {
            width: 18px;
            opacity: 0.8;
        }
    `,
    msgError: css`
        font-size: 14px;
        color: ${colors.red};
        white-space: nowrap;
        width: 100%;
        padding-right: 22px;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
    `,
    action: css`
        display: flex;
        gap: 12px;
    `,
    groupInput: css`
        align-items: flex-start;
        label {
            padding-top: 8px;
        }
    `,
    groupAmount: css`
        flex: 1;
        display: flex;
        align-items: flex-start;
        gap: 8px;
    `,
    btnMax: css`
        border: 1px solid ${colors.blue};
        height: 36px;
        padding: 0 8px;
        color: ${colors.white};
        font-size: 14px;
        border-radius: 6px;
        &:active {
            background: ${colors.primary};
        }
    `,
    inputAmount: css`
        border-color: ${colors.darkPrimary};
        margin-bottom: 4px;
    `,
};

export default FastTransferGame;
