import React, { useEffect, useRef, useState } from 'react';
import styles from './otp-input.style';
let currentIdx: number = 0;

interface Props {
    onSubmit: (value: any, cb: () => void) => void;
    onCancel: () => void;
}
function OtpInput(props: Props) {
    const { onSubmit, onCancel } = props;
    const inputRef = useRef<HTMLInputElement>(null);
    const [otp, setOtp] = useState<string[]>(new Array(6).fill(''));
    const [activeOtpIdx, setActiveOtpIdx] = useState(0);
    const [enableSubmit, setEnableSubmit] = useState(false);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [activeOtpIdx]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const newOtp: string[] = [...otp];
        newOtp[currentIdx] = value.substring(value.length - 1);

        if (!value) setActiveOtpIdx(currentIdx - 1);
        else setActiveOtpIdx(currentIdx + 1);
        const isEnableSubmit = newOtp.join('').length === 6;
        setEnableSubmit(isEnableSubmit);
        setOtp(newOtp);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        currentIdx = index;
        if (e.key === 'Backspace') {
            setActiveOtpIdx(currentIdx - 1);
        }
    };

    const handleSubmitOtp = () => {
        if (!enableSubmit) return;

        const otpValue = otp.join('');
        onSubmit(otpValue, () => {
            setOtp(new Array(6).fill(''));
            setActiveOtpIdx(0);
            if (inputRef.current) {
                inputRef.current.focus();
            }
        });
    };

    return (
        <div className={styles.wrap}>
            <div className={styles.otpContent}>
                {otp.map((_, index) => {
                    return (
                        <React.Fragment key={index}>
                            <input
                                ref={index === activeOtpIdx ? inputRef : null}
                                type="tel"
                                className={styles.inputOtp}
                                onChange={handleChange}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                value={otp[index]}
                            />
                        </React.Fragment>
                    );
                })}
            </div>

            {/* <p className={styles.timeCountdown}>
                <span>Mã xác nhận hết hạn sau {timeCountDown}</span>
            </p> */}
            <div className="action-group-modal">
                <button type="button" className="btn-cancel" onClick={onCancel}>
                    Huỷ
                </button>
                <button type="button" className="btn-submit btn-primary" disabled={!enableSubmit} onClick={handleSubmitOtp}>
                    Xác nhận
                </button>
            </div>
        </div>
    );
}

export default OtpInput;
