import { css } from '@emotion/css';
import { colors } from 'constants/theme';

const otpInputStyles = {
    wrap: css`
        position: relative;
    `,

    otpContent: css`
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 32px;
    `,
    inputOtp: css`
        width: 50px;
        height: 50px;
        margin: 4px;
        border-radius: 12px;
        border: 1px solid ${colors.gray};
        font-size: 20px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;

        text-align: center;
        background: #030a1c;
        color: ${colors.white};
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        &:focus {
            border-color: ${colors.primary};
        }
    `,

    timeCountdown: css`
        font-size: 12px;
        text-align: center;
        margin-top: 16px;
    `,
    btnCancel: css``,
    btnSubmit: css`
        margin-top: 20px;
        border-radius: 12px;

        padding: 14px 0;
        background: ${colors.primary};
        color: ${colors.white};
        font-size: 16px;
        font-weight: 700;
    `,
};
export default otpInputStyles;
