import { useEffect, useRef, useState } from 'react';
import styles from './account-option.style';
import { RootState, useAppDispatch, useAppSelector } from 'cache/store';
import toast from 'react-hot-toast';
import { SubmitHandler, useForm } from 'react-hook-form';
import globalStyle from 'constants/global.style';
import { checkWhiteSpaceString } from 'utils/function';
import userApi from 'apis/userApi';
import { cacheUserInfo } from 'features/authSlice';
import moment from 'moment';
import LoadingIcon from 'components/loading/LoadingIcon';
import Modal from 'components/modal/Modal';
import VerifyPhone from 'components/verify-phone/VerifyPhone';
import DatePicker from 'components/date-picker/DatePicker';
import { ConfigFirebaseModel } from 'models/commonModel';
import firebaseInitConfig from 'constants/firebase';
import { Auth } from 'firebase/auth';

interface PassUpdate {
    order: number;
    value: string;
    key: string;
}
interface FormValuesAccount {
    oldpwd: string;
    newpwd: string;
    newpwd2: string;
    oldcheckpwd: string;
    newcheckpwd: string;
    newcheckpwd2: string;
    nickname: string;
    birthDay: string;
    phone: string;
}
function PersonalInfo() {
    const dispatch = useAppDispatch();
    const recaptchaRef = useRef<HTMLDivElement>(null);

    const { userInfo } = useAppSelector((state: RootState) => state.auth);

    const [birthDay, setBirthDay] = useState<Date | null>(userInfo?.birthday ? new Date(userInfo.birthday) : new Date());
    const [nickname, setNickname] = useState(userInfo.nickname);
    const [phone, setPhone] = useState<string | null>(userInfo?.phone ? userInfo.phone.replace('+84', '0') : userInfo?.phone);
    const [firebaseConfig, setFirebaseConfig] = useState<Auth>({} as Auth);
    const [showVerifyPhone, setShowVerifyPhone] = useState(false);
    const [loading, setLoading] = useState(false);

    const [showOldPwd, setShowOldPwd] = useState(false);
    const [showNewpwd, setShowNewpwd] = useState(false);
    const [showNewpwd2, setShowNewpwd2] = useState(false);
    const [showOldpaypwd, setShowOldpaypwd] = useState(false);
    const [showNewpaypwd, setShowNewpaypwd] = useState(false);
    const [showNewpaypwd2, setShowNewpaypwd2] = useState(false);

    const {
        register,
        handleSubmit,
        setFocus,
        setError,
        clearErrors,
        setValue,
        formState: { errors },
    } = useForm<FormValuesAccount>();

    useEffect(() => {
        const getConfigFirebase = async () => {
            try {
                const { data } = await userApi.getFirebaseConfig({});
                if (!data.msg) {
                    initConfigFirebase(data.data[0]);
                } else {
                    toast.error(data.msg);
                }
            } catch (error) {
                console.log(error);
            }
        };
        getConfigFirebase();
    }, []);

    const initConfigFirebase = (config: ConfigFirebaseModel = {} as ConfigFirebaseModel) => {
        const auth = firebaseInitConfig(config);
        setFirebaseConfig(auth);
    };

    const copyText = (text: string) => {
        navigator.clipboard.writeText(text);
        toast.success('Sao chép thành công!');
    };

    const submitChangeNickname = async () => {
        if (nickname.trim() === '') {
            setError('nickname', {
                message: 'Biệt danh không hợp lệ',
            });
            return;
        }
        if (errors['nickname']) return;

        setLoading(true);
        try {
            const { data } = await userApi.changeNickname({ userNickname: nickname });
            if (!data.msg) {
                const newUserInfo = { ...userInfo, nickname };
                dispatch(cacheUserInfo(newUserInfo));
                toast.success('Cập nhật thành công!');
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const changeInputBirthday = (date: null | Date) => {
        if (date) {
            clearErrors('birthDay');
        }
        setBirthDay(date);
    };

    const submitChangeBirthday = async (hideAlert?: boolean) => {
        if (!hideAlert) {
            if (!birthDay) {
                setError('birthDay', {
                    message: 'Ngày sinh không hợp lệ',
                });
                return;
            }
            if (errors['birthDay']) return;
            setLoading(true);
        }

        try {
            const { data } = await userApi.changeBirthday({ userBirthday: moment(birthDay).format('yyyy-MM-DD') });
            if (!data.msg) {
                if (!hideAlert) {
                    toast.success('Cập nhật thành công!');
                }
                const newUserInfo = { ...userInfo, birthday: moment(birthDay).format('yyyy-MM-DD') };
                dispatch(cacheUserInfo(newUserInfo));
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const changePhone = (phone: string | null) => {
        if (phone) {
            if (!/^-?\d*\.?\d*$/.test(phone)) {
                setError('phone', {
                    message: 'Số điện thoại không hợp lệ',
                });
            } else if (phone.length > 11) {
                setError('phone', {
                    message: 'Số điện thoại tối đa 11 ký tự',
                });
            } else {
                clearErrors('phone');
            }
        } else {
            clearErrors('phone');
        }
        setPhone(phone);
    };

    const submitVerifyPhone = async () => {
        if (!phone) {
            setError('phone', {
                message: '',
            });
            return;
        }
        if (errors['phone']) return;

        setLoading(true);
        try {
            const { data } = await userApi.phoneCheck({ phone });
            if (!data.msg) {
                setShowVerifyPhone(true);
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const onEditAccount: SubmitHandler<FormValuesAccount> = (values) => {
        const { newcheckpwd, newcheckpwd2, newpwd, newpwd2, oldcheckpwd, oldpwd } = values;
        const passlogin = [
            { order: 1, value: oldpwd, key: 'oldpwd' },
            { order: 2, value: newpwd, key: 'newpwd' },
            { order: 3, value: newpwd2, key: 'newpwd2' },
        ];
        const passPayment = [
            { order: 1, value: oldcheckpwd, key: 'oldcheckpwd' },
            { order: 2, value: newcheckpwd, key: 'newcheckpwd' },
            { order: 3, value: newcheckpwd2, key: 'newcheckpwd2' },
        ];

        submitUpdatePass(passlogin, 'PersonPwdBean_loginPWD');
        submitUpdatePass(passPayment, 'PersonPwdBean_checkPWD');

        //check change birthday
        if (moment(birthDay).format('yyyy/MM/DD') !== moment(userInfo.birthday).format('yyyy/MM/DD')) {
            if (!birthDay) return;
            submitChangeBirthday(true);
        }
    };

    const checkValidPass = (listPass: PassUpdate[]) => {
        const isPassNull = listPass.every((item) => !item.value);
        const isPassHasData = listPass.every((item) => item.value);
        return {
            isValidPass: isPassNull || isPassHasData,
            isNull: isPassNull,
        };
    };

    const submitUpdatePass = async (listPass: PassUpdate[], typePass: 'PersonPwdBean_loginPWD' | 'PersonPwdBean_checkPWD') => {
        const { isValidPass, isNull } = checkValidPass(listPass);
        if (isValidPass) {
            if (!isNull) {
                setLoading(true);
                let passObj: { [key: string]: string } = {};

                listPass.forEach((passItem) => {
                    passObj = { ...passObj, [passItem.key]: passItem.value };
                });

                const payload = {
                    ...passObj,
                    flag: typePass,
                };
                try {
                    const { data } = await userApi.editPassWord(payload);
                    if (!data.msg) {
                        let msgAlert = 'Cập nhật mật khẩu thành công!';
                        if (typePass === 'PersonPwdBean_checkPWD') {
                            msgAlert = 'Cập nhật mật khẩu thanh toán thành công!';
                        }
                        toast.success(msgAlert);

                        //reset input
                        listPass.forEach((passItem: any) => {
                            setValue(passItem.key, '');
                        });
                    } else {
                        toast.error(data.msg);
                    }
                } catch (error) {
                    console.log(error);
                } finally {
                    setLoading(false);
                }
            }
        } else {
            const passItemFocus = listPass.find((item) => !item.value);
            if (passItemFocus) {
                setFocus(passItemFocus.key as any);
                setError(passItemFocus.key as any, {
                    message: '',
                });
            }
        }
    };

    const formatHideInfo = (value: string) => {
        if (value) {
            return value.length > 3 ? `${value.slice(0, 4)}****${value.slice(value.length - 3, value.length)}` : `${value.slice(0, 1)}***`;
        }
        return '';
    };

    const confirmPhoneNumber = async (idToken: string) => {
        try {
            const { data } = await userApi.confirmNumPhone({ phone, firebaseId: idToken });
            if (!data.msg) {
                toast.success('Cập nhật số điện thoại thành công');
                setShowVerifyPhone(false);

                //update user info cache
                const newUserInfo = { ...userInfo, verifiedphone: 1, phone };
                dispatch(cacheUserInfo(newUserInfo));
            } else {
                toast.error(data.msg);
            }
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <>
            <div className={`${styles.wrap} ${styles.mw800}`}>
                <h6 className={styles.title}>Thông Tin Cá Nhân</h6>
                <div className={`${styles.heading} ${styles.flexbox}`}>
                    <div className={`${styles.accountName} ${styles.flex50}`}>
                        <p>
                            Tên tài khoản: <span>{userInfo.loginname}</span>
                        </p>
                        <button type="button" className={`${styles.btnCopy} btn-primary`} onClick={() => copyText(userInfo.loginname)}>
                            <img src={require('assets/images/icons/copy.png')} alt="" />
                        </button>
                    </div>
                    <p className={styles.flex50}>
                        Cấp độ thành viên: <span>VIP {userInfo.monthlevelvip}</span>
                    </p>
                </div>
                <form onSubmit={handleSubmit(onEditAccount)}>
                    <div className={styles.flexbox} style={{ paddingTop: '20px', paddingBottom: '8px' }}>
                        <div className={`${styles.groupInput} ${styles.flex50}`}>
                            <label htmlFor="">Biệt Danh</label>
                            <div className={styles.wrapInput}>
                                <input value={nickname} onChange={(e) => setNickname(e.target.value)} className={`input-common`} type="text" placeholder="Nhập ký tự chữ và số" />
                                <button type="button" className={styles.btnUpdateInput} onClick={submitChangeNickname}>
                                    Cập Nhật
                                </button>
                            </div>
                            {/* base error  */}
                            <div className={globalStyle.errorInput}>{errors['nickname'] && <small>{errors['nickname'].message || 'Vui lòng nhập trường này!'}</small>}</div>
                        </div>
                        <div className={`${styles.groupInput} ${styles.flex50}`}>
                            <label htmlFor="">Email</label>
                            <div className={styles.wrapInput}>
                                <input defaultValue={formatHideInfo(userInfo?.email)} className={`input-common`} type="text" disabled placeholder="Email" />
                            </div>
                        </div>
                        <div className={`${styles.groupInput} ${styles.flex50}`}>
                            <label htmlFor="">Số Điện Thoại</label>
                            <div className={styles.wrapInput}>
                                <input
                                    value={phone || ''}
                                    onChange={(e) => changePhone(e.target.value)}
                                    className={`input-common`}
                                    type="text"
                                    disabled={userInfo.verifiedphone === 1}
                                    placeholder="Số điện thoại"
                                />
                                {userInfo.verifiedphone === 0 && (
                                    <button type="button" className={`${styles.btnUpdateInput} ${styles.btnVerify}`} onClick={submitVerifyPhone}>
                                        Xác Thực
                                    </button>
                                )}
                                {userInfo.verifiedphone === 1 && (
                                    <div className={styles.verified}>
                                        <img src={require('assets/images/icons/success.png')} alt="" />
                                        <span>Đã Xác Thực</span>
                                    </div>
                                )}
                            </div>
                            {/* base error  */}
                            <div className={globalStyle.errorInput}>{errors['phone'] && <small>{errors['phone'].message || 'Vui lòng nhập trường này!'}</small>}</div>
                        </div>
                        <div className={`${styles.groupInput} ${styles.flex50}`}>
                            <label htmlFor="">Ngày Sinh</label>
                            <div className={styles.wrapInput}>
                                <DatePicker dateSelect={birthDay} onSelectDate={(date) => changeInputBirthday(date)} holder="Ngày sinh" yearEnd={1970} />
                                <button type="button" className={styles.btnUpdateInput} onClick={() => submitChangeBirthday()}>
                                    Cập Nhật
                                </button>
                            </div>
                            {/* base error  */}
                            <div className={globalStyle.errorInput}>{errors['birthDay'] && <small>{errors['birthDay'].message || 'Vui lòng nhập trường này!'}</small>}</div>
                        </div>
                    </div>
                    <div className={`${styles.flexbox} ${styles.groupPass}`}>
                        <div className={`${styles.groupInput} ${styles.flex33}`}>
                            <label htmlFor="">Mật Khẩu Đăng Nhập</label>
                            <div className={styles.wrapInput}>
                                <input {...register('oldpwd')} className={`input-common`} type={showOldPwd ? 'tex' : 'password'} placeholder="Mật khẩu cũ" />
                                <div className={globalStyle.togglePass} onClick={() => setShowOldPwd(!showOldPwd)}>
                                    {showOldPwd ? <img src={require('assets/images/icons/eye_on.png')} alt="" /> : <img src={require('assets/images/icons/eye_off.png')} alt="" />}
                                </div>
                            </div>
                            {/* base error  */}
                            <div className={globalStyle.errorInput}>{errors['oldpwd'] && <small>{errors['oldpwd'].message || 'Vui lòng nhập trường này!'}</small>}</div>
                        </div>
                        <div className={`${styles.groupInput} ${styles.flex33}`}>
                            <div className={styles.wrapInput}>
                                <input {...register('newpwd')} className={`input-common`} type={showNewpwd ? 'tex' : 'password'} placeholder="Mật khẩu mới" />
                                <div className={globalStyle.togglePass} onClick={() => setShowNewpwd(!showNewpwd)}>
                                    {showNewpwd ? <img src={require('assets/images/icons/eye_on.png')} alt="" /> : <img src={require('assets/images/icons/eye_off.png')} alt="" />}
                                </div>
                            </div>
                            {/* base error  */}
                            <div className={globalStyle.errorInput}>{errors['newpwd'] && <small>{errors['newpwd'].message || 'Vui lòng nhập trường này!'}</small>}</div>
                        </div>
                        <div className={`${styles.groupInput} ${styles.flex33}`}>
                            <div className={styles.wrapInput}>
                                <input {...register('newpwd2')} className={`input-common`} type={showNewpwd2 ? 'tex' : 'password'} placeholder="Nhập lại mật khẩu mới" />
                                <div className={globalStyle.togglePass} onClick={() => setShowNewpwd2(!showNewpwd2)}>
                                    {showNewpwd2 ? <img src={require('assets/images/icons/eye_on.png')} alt="" /> : <img src={require('assets/images/icons/eye_off.png')} alt="" />}
                                </div>
                            </div>
                            {/* base error  */}
                            <div className={globalStyle.errorInput}>{errors['newpwd2'] && <small>{errors['newpwd2'].message || 'Vui lòng nhập trường này!'}</small>}</div>
                        </div>
                    </div>
                    <div className={`${styles.flexbox} ${styles.groupPass}`}>
                        <div className={`${styles.groupInput} ${styles.flex33}`}>
                            <label htmlFor="">Mật Khẩu Thanh Toán</label>
                            <div className={styles.wrapInput}>
                                <input {...register('oldcheckpwd')} className={`input-common`} type={showOldpaypwd ? 'tex' : 'password'} placeholder="Mật khẩu cũ" />
                                <div className={globalStyle.togglePass} onClick={() => setShowOldpaypwd(!showOldpaypwd)}>
                                    {showOldpaypwd ? <img src={require('assets/images/icons/eye_on.png')} alt="" /> : <img src={require('assets/images/icons/eye_off.png')} alt="" />}
                                </div>
                            </div>
                            {/* base error  */}
                            <div className={globalStyle.errorInput}>{errors['oldcheckpwd'] && <small>{errors['oldcheckpwd'].message || 'Vui lòng nhập trường này!'}</small>}</div>
                        </div>
                        <div className={`${styles.groupInput} ${styles.flex33}`}>
                            <div className={styles.wrapInput}>
                                <input {...register('newcheckpwd')} className={`input-common`} type={showNewpaypwd ? 'tex' : 'password'} placeholder="Mật khẩu mới" />
                                <div className={globalStyle.togglePass} onClick={() => setShowNewpaypwd(!showNewpaypwd)}>
                                    {showNewpaypwd ? <img src={require('assets/images/icons/eye_on.png')} alt="" /> : <img src={require('assets/images/icons/eye_off.png')} alt="" />}
                                </div>
                            </div>
                            {/* base error  */}
                            <div className={globalStyle.errorInput}>{errors['newcheckpwd'] && <small>{errors['newcheckpwd'].message || 'Vui lòng nhập trường này!'}</small>}</div>
                        </div>
                        <div className={`${styles.groupInput} ${styles.flex33}`}>
                            <div className={styles.wrapInput}>
                                <input {...register('newcheckpwd2')} className={`input-common`} type={showNewpaypwd2 ? 'tex' : 'password'} placeholder="Nhập lại mật khẩu mới" />
                                <div className={globalStyle.togglePass} onClick={() => setShowNewpaypwd2(!showNewpaypwd2)}>
                                    {showNewpaypwd2 ? <img src={require('assets/images/icons/eye_on.png')} alt="" /> : <img src={require('assets/images/icons/eye_off.png')} alt="" />}
                                </div>
                            </div>
                            {/* base error  */}
                            <div className={globalStyle.errorInput}>{errors['newcheckpwd2'] && <small>{errors['newcheckpwd2'].message || 'Vui lòng nhập trường này!'}</small>}</div>
                        </div>
                    </div>
                    <div style={{ marginTop: '16px', width: '50%' }}>
                        <button type="submit" className={`btn-primary ${styles.btnSubmit}`}>
                            Lưu Thay Đổi
                        </button>
                    </div>
                </form>
            </div>
            {/* div generate recaptcha firebase */}
            <div ref={recaptchaRef}>
                <div id="recaptcha-container"></div>
            </div>
            <Modal isShow={showVerifyPhone} onClose={() => setShowVerifyPhone(false)} width="400px">
                {phone ? <VerifyPhone ref={recaptchaRef} onCancel={() => setShowVerifyPhone(false)} phone={phone} onSuccess={confirmPhoneNumber} firebaseConfig={firebaseConfig} /> : <></>}
            </Modal>
            {loading && <LoadingIcon />}
        </>
    );
}

export default PersonalInfo;
